<template>
  <div class="page">
    <!-- tab栏 -->
    <div class="tab_box_out">
      <div class="tab_box">
        <div :class="['item', { active: tabNum == 1 }]" @click="tabClick(1)">
          待受理
        </div>
        <div :class="['item', { active: tabNum == 2 }]" @click="tabClick(2)">
          已接单
        </div>
        <div :class="['item', { active: tabNum == 3 }]" @click="tabClick(3)">
          处理中
        </div>
        <div :class="['item', { active: tabNum == 4 }]" @click="tabClick(4)">
          已完成
        </div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="list">
      <div class="box">
        <v-list
          :finished="finished"
          @onLoad="onLoad"
          :finishedText="finishedText"
          ref="list"
        >
          <div
            class="item"
            @click="toDetail(item)"
            v-for="(item, index) in listData"
            :key="index"
          >
            <div class="img_box">
              <img :src="imgStrSplit(item.eventPicture)" alt="" />
            </div>
            <div class="detail_box">
              <div class="name">{{ item.eventDescription }}</div>
              <div class="type">所属分类：{{ item.incidentName }}</div>
              <div class="time">{{ item.createTime }}</div>
            </div>
            <div class="status_box">
              <p v-if="item.status == 4">已完成<span class="green"></span></p>
              <p v-else-if="item.status == 2">
                已接单<span class="blue"></span>
              </p>
              <p v-else-if="item.status == 1">
                待受理<span class="red"></span>
              </p>
              <p v-else>处理中<span class="yellow"></span></p>
            </div>
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>

<script>
import { snapshotListUrl } from "./api.js";
export default {
  name: "myReport",
  data() {
    return {
      tabNum: 1,
      finishedText: "没有更多了",
      finished: false,
      listData: [],
      requestData: {
        curPage: 1,
        pageSize: 7,
      },
    };
  },
  computed: {
    userId() {
      return this.$store.state.userId;
    },
  },
  methods: {
    onLoad() {
      this.getList();
    },
    getList() {
      //获取随手拍列表
      let params = {
        pageNum: this.requestData.curPage++,
        pageSize: this.requestData.pageSize,
        userId: this.userId,
        status: this.tabNum,
        workTypeName: "随手拍",
      };
      this.$axios
        .get(`${snapshotListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            if (res.data.pages <= res.data.current) {
              this.finished = true;
            } else {
              this.$refs.list._data.loading = false;
            }
            this.finishedText = "没有更多了";
            if (res.data.records.length === 0) {
              this.finishedText = "";
              return;
            }
            res.data.records.forEach((item) => {
              this.listData.push(item);
            });
          } else {
          }
        });
    },
    toDetail(data) {
      this.$router.push({
        name: "clapCasuallyDetail",
        query: {
          id: data.id,
        },
      });
    },
    tabClick(num) {
      this.listData = [];
      this.tabNum = num;
      this.requestData.curPage = 1;
      this.finished = false;
      this.onLoad();
    },
    imgStrSplit(str) {
      //多个图片截取第一张
      if (str.indexOf(",") != -1) {
        return str.split(",")[0];
      } else {
        return str;
      }
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  height: 100vh;
  background: #fafafa;
  padding-bottom: 30px;
  box-sizing: border-box;

  .tab_box_out {
    width: 100%;
    height: 82px;

    .tab_box {
      z-index: 10000;
      position: fixed;
      top: 0;
      left: 0;
      box-sizing: border-box;
      padding: 0 30px;
      width: 100%;
      height: 82px;
      background: #ffffff;
      display: flex;
      justify-content: space-between;

      .item {
        box-sizing: border-box;
        width: 140px;
        font-size: 30px;
        color: rgba(0, 0, 0, 0.85);
        text-align: center;
        line-height: 82px;
      }

      .active {
        border-bottom: 6px solid #3680ff;
        font-size: 30px;
        font-weight: 600;
        color: #3680ff;
      }
    }
  }

  .list {
    height: calc(100% - 82px);
    margin-top: 30px;
    width: 100%;
    padding: 0 30px;
    overflow-y: scroll;
    box-sizing: border-box;

    // .box1,
    // .box2,
    // .box3,
    // .box4 {
    .box {
      // height: calc(100% - 82px);
      width: 100%;
      box-sizing: border-box;
      background: #ffffff;
      box-shadow: 0px 4px 16px 0px rgba(227, 227, 227, 0.5);
      border-radius: 16px;
      padding: 0 32px 0 38px;

      .item {
        box-sizing: border-box;
        width: 100%;
        height: 212px;
        border-bottom: 2px solid rgba(0, 0, 0, 0.06);
        display: flex;
        padding: 36px 0 32px;

        .status_box {
          flex: 1;
          height: 100%;

          p {
            font-size: 26px;
            color: rgba(0, 0, 0, 0.5);
            text-align: right;

            span {
              width: 10px;
              height: 10px;

              border-radius: 50%;
              display: inline-block;
              position: relative;
              top: -10px;
              right: -2px;
            }

            .green {
              background: #66b95a;
            }

            .blue {
              background: #8db0e8;
            }

            .red {
              background: #c33333;
            }

            .yellow {
              background: #f9980b;
            }
          }
        }

        .detail_box {
          box-sizing: border-box;
          width: 330px;
          height: 100%;
          padding-left: 14px;

          .name {
            margin-top: 4px;
            font-size: 34px;
            font-weight: 600;
            color: rgba(0, 0, 0, 0.85);
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }

          .type {
            font-size: 24px;
            color: rgba(0, 0, 0, 0.85);
          }

          .time {
            margin-top: 32px;
            font-size: 24px;
            color: rgba(0, 0, 0, 0.41);
          }
        }

        .img_box {
          width: 144px;
          height: 100%;

          img {
            width: 144px;
            height: 144px;
          }
        }
      }

      .item:last-child {
        border: none;
      }
    }
  }

  // }
}
</style>
